import {
    Column,
    EComponentSize, ISelectOption,
    ITextFieldAction,
    ITextFieldMethods,
    Label,
    Row,
    SearchField,
    Select, SelectOptionValueType,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import {
    STICKER_SEARCH_CONTENT_MARKS_NAME,
    STICKER_SEARCH_ORDER_NAME,
    STICKER_SEARCH_QUERY_NAME,
    STICKER_SEARCH_SORT_NAME,
} from '@common';
import { StickersListMode, useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { ContentMarkAction, IContentMark, ISticker } from '@typings';
import { ITabParams, StickersList, Tabs } from '@views';

import s from './StickersSearch.scss';

enum SearchTextFieldAction {
    RESET = 'reset',
}

interface StickersSearchProps {
    setStickerToEdit?: (sticker: ISticker) => void,
}

const DEFAULT_SORT_DIRECTION = -1;
const MODERATED_AT_KEY = 'moderator-area.stickers-search.sort-field.moderated-at';
const CREATED_AT_KEY = 'moderator-area.stickers-search.sort-field.created-at';
const DISABLED_AT_KEY = 'moderator-area.stickers-search.sort-field.disabled-at';
const DISABLE_COUNT_KEY = 'moderator-area.stickers-search.sort-field.disable-count';
const USAGE_COUNT_KEY = 'moderator-area.stickers-search.sort-field.usage-count';

export const StickersSearch = () => {
    const { setStickerToEdit } = useOutletContext<StickersSearchProps>();

    const app = useAppContext();
    const {
        translation: { t },
        contentMarks: {
            contentMarks,
            contentMarksByCategory,
        },
    } = app;

    const { goToModerationStickersTab } = useNavigator();

    const { mode, scrollIndex } = useParams();
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';
    const sort = searchParams.get(STICKER_SEARCH_SORT_NAME) ?? '';
    const order = searchParams.get(STICKER_SEARCH_ORDER_NAME) ?? '';
    const queryContentMarks = searchParams.get(STICKER_SEARCH_CONTENT_MARKS_NAME) ?? '';

    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const [selectedContentMarks, setSelectedContentMarks] = useState<IContentMark[]>([]);

    const [enteredSearchQuery, setEnteredSearchQuery] = useState(query || '');
    const [isHiddenFilters, setIsHiddenFilters] = useState(true);
    const [stickersListMode, setStickersListMode] =
        useState<StickersListMode>(mode
            ? StickersListMode[mode.toUpperCase() as keyof typeof StickersListMode]
            : StickersListMode.APPROVED);

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === SearchTextFieldAction.RESET) {
            setEnteredSearchQuery('');
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !enteredSearchQuery,
        },
    ];

    const TABS: Array<ITabParams<StickersListMode>> = [
        { caption: t('moderator-area.stickers-list.approved'), tag: StickersListMode.APPROVED },
        { caption: t('moderator-area.stickers-list.blacklist'), tag: StickersListMode.BLACKLIST },
        { caption: t('moderator-area.stickers-list.blacklist-date'), tag: StickersListMode.BLACKLIST_DATE },
        { caption: t('moderator-area.stickers-list.moderation'), tag: StickersListMode.MODERATION },
        { caption: t('moderator-area.stickers-list.rejected'), tag: StickersListMode.REJECTED },
    ];

    const TAB_SORT_DEFAULT_FIELDS = {
        [StickersListMode.APPROVED]: {
            defaultValue: 'moderatedAt',
        },
        [StickersListMode.BLACKLIST]: {
            defaultValue: 'disableCount',
        },
        [StickersListMode.BLACKLIST_DATE]: {
            defaultValue: 'disabledAt',
        },
        [StickersListMode.MODERATION]: {
            defaultValue: 'createdAt',
        },
        [StickersListMode.REJECTED]: {
            defaultValue: 'moderatedAt',
        },
    };

    const [sortField, setSortField] = useState<SelectOptionValueType | undefined>(
        sort || TAB_SORT_DEFAULT_FIELDS[stickersListMode].defaultValue,
    );
    const [sortDirection, setSortDirection] = useState<SelectOptionValueType | undefined>(parseInt(order) || DEFAULT_SORT_DIRECTION);

    const getOption = (captionKey: string, value: string): ISelectOption => {
        if (sortField) {
            return { caption: t(captionKey), value: value, selected: sortField === value };
        }

        return { caption: '' };
    };

    const TAB_SORT_FIELDS = {
        [StickersListMode.APPROVED]: {
            options: [
                getOption(MODERATED_AT_KEY, 'moderatedAt'),
                getOption(CREATED_AT_KEY, 'createdAt'),
                getOption(DISABLE_COUNT_KEY, 'disableCount'),
                getOption(USAGE_COUNT_KEY, 'usageCount'),
            ],
        },
        [StickersListMode.BLACKLIST]: {
            options: [
                getOption(MODERATED_AT_KEY, 'moderatedAt'),
                getOption(CREATED_AT_KEY, 'createdAt'),
                getOption(DISABLE_COUNT_KEY, 'disableCount'),
                getOption(USAGE_COUNT_KEY, 'usageCount'),
            ],
        },
        [StickersListMode.BLACKLIST_DATE]: {
            options: [
                getOption(MODERATED_AT_KEY, 'moderatedAt'),
                getOption(CREATED_AT_KEY, 'createdAt'),
                getOption(DISABLED_AT_KEY, 'disabledAt'),
                getOption(USAGE_COUNT_KEY, 'usageCount'),
            ],
        },
        [StickersListMode.MODERATION]: {
            options: [
                getOption(CREATED_AT_KEY, 'createdAt'),
                getOption(DISABLE_COUNT_KEY, 'disableCount'),
                getOption(USAGE_COUNT_KEY, 'usageCount'),
            ],
        },
        [StickersListMode.REJECTED]: {
            options: [
                getOption(MODERATED_AT_KEY, 'moderatedAt'),
                getOption(CREATED_AT_KEY, 'createdAt'),
                getOption(DISABLE_COUNT_KEY, 'disableCount'),
                getOption(USAGE_COUNT_KEY, 'usageCount'),
            ],
        },
    };

    const onSortFieldChanged = (sort: SelectOptionValueType | undefined) => {
        if (sort?.toString() !== sortField?.toString()) {
            setSortField(sort);
        }
    };

    const onTabSelected = (mode: StickersListMode = StickersListMode.APPROVED) => {
        if (mode !== stickersListMode) {
            setSortField(TAB_SORT_DEFAULT_FIELDS[mode].defaultValue);
            goToModerationStickersTab(mode, enteredSearchQuery);
            setStickersListMode(mode);
        }
    };

    const onContentMarkSelect = (contentMark: IContentMark) => {
        if (selectedContentMarks.some((m) => m._id === contentMark._id)) {
            setSelectedContentMarks(selectedContentMarks.filter((m) => m._id !== contentMark.id));
        } else {
            setSelectedContentMarks([...selectedContentMarks, contentMark]);
        }
    };

    const onDeleteMarkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, contentMark: IContentMark) => {
        setSelectedContentMarks(selectedContentMarks.filter((m) => m._id !== contentMark.id));
        e.preventDefault();
        e.stopPropagation();
    };

    const onClearClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setSelectedContentMarks([]);
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (contentMarks) {
            if (enteredSearchQuery !== query) {
                goToModerationStickersTab(stickersListMode,
                    enteredSearchQuery,
                    undefined,
                    sort,
                    parseInt(order),
                    selectedContentMarks.map( (m) => m.id),
                );
            } else {
                goToModerationStickersTab(
                    stickersListMode,
                    enteredSearchQuery,
                    scrollIndex, sortField?.toString(),
                    parseInt(sortDirection?.toString() ?? DEFAULT_SORT_DIRECTION.toString()),
                    selectedContentMarks.map( (m) => m.id),
                );
            }
        }
    }, [enteredSearchQuery, sortField, sortDirection, selectedContentMarks]);

    useEffect(() => {
        if (queryContentMarks && contentMarks) {
            const queryMarks = [];
            const markIds = queryContentMarks.split(',');

            for (const markId of markIds) {
                const mark = contentMarks.find((m) => m.id === markId);

                if (mark) {
                    queryMarks.push(mark);
                }
            }

            if (queryMarks.length > 0) {
                setSelectedContentMarks(queryMarks);
            }
        }
    }, [contentMarks]);

    return (
        <Column rowGap={10}>
            <Row className={s.blockRow} margin={{ top: 20 }}>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    placeholder={t('moderator-area.stickers-list.find-stickers')}
                    defaultValue={enteredSearchQuery}
                    debounceTime={500}
                    actions={searchFieldActions}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                />
            </Row>
            <Row>
                <Column grow>
                    <Row columnGap={10}>
                        <Column grow>
                            <Row>
                                <div onClick={() => setIsHiddenFilters(!isHiddenFilters)}
                                    className={s.contentMarksControl}>
                                    <div className={s.contentMarksControlArrow}>{isHiddenFilters ? '↓' : '↑'}</div>
                                    {selectedContentMarks.length > 0 &&
                                        <div onClick={(e) => onClearClick(e)} className={s.clearButton}>
                                            <IconCloseLine/>
                                        </div>
                                    }
                                    <div>
                                        {selectedContentMarks.length > 0
                                            ? (
                                                <div className={s.selectedContentMarks}>
                                                    {selectedContentMarks.map((mark) => (
                                                        <div
                                                            onClick={(e) => onDeleteMarkClick(e, mark)}
                                                            className={cn(s.item, {
                                                                [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                                [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                            })} key={mark.id}>
                                                            <div className={s.itemText}>{mark.name}</div>
                                                            <div className={s.itemDeleteButton}><IconCloseLine/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                            : (<>{t('moderator-area.stickers-search.filters')}</>)
                                        }
                                    </div>
                                </div>
                            </Row>
                        </Column>
                        <Column width={200}>
                            <Row grow className={s.blockRow}>
                                <Select
                                    options={
                                        TAB_SORT_FIELDS[stickersListMode].options
                                    }
                                    onValueChanged={(value) => onSortFieldChanged(value?.toString() ?? sortField)}
                                />
                            </Row>
                        </Column>
                        <Column width={100}>
                            <Row grow className={s.blockRow}>
                                <Select
                                    options={
                                        [
                                            {
                                                caption: t('moderator-area.stickers-search.sort-direction.asc'),
                                                value: 1,
                                                selected: sortDirection === 1,
                                            },
                                            {
                                                caption: t('moderator-area.stickers-search.sort-direction.desc'),
                                                value: -1,
                                                selected: sortDirection === -1,
                                            },
                                        ]
                                    }
                                    onValueChanged={(value) => setSortDirection(value)}
                                />
                            </Row>
                        </Column>
                    </Row>
                    <Row className={cn(
                        {
                            [s.hidden]: isHiddenFilters,
                            [s.show]: !isHiddenFilters,
                        },
                    )}>
                        <Column style={{ marginTop: 15 }}>
                            <Row>
                                <Label
                                    padding={{ top: 0 }}
                                    caption={t('moderator-area.sticker-editor.content-marks')}
                                    size={EComponentSize.MEDIUM}
                                />
                            </Row>
                            {contentMarksByCategory && Object.keys(contentMarksByCategory)
                                .filter((c) => !contentMarksByCategory[c]
                                    .every((m) => selectedContentMarks.includes(m)))
                                .map((category) => (
                                    <Row key={category}>
                                        <Column grow>
                                            <Row>
                                                <Label
                                                    padding={{ top: 0 }}
                                                    caption={category}
                                                    size={EComponentSize.SMALL}
                                                />
                                            </Row>
                                            <Row grow>
                                                <div className={s.contentMarks}>
                                                    {contentMarksByCategory[category]
                                                        .filter((x) => !selectedContentMarks.includes(x))
                                                        .map((mark) => (
                                                            <div
                                                                onClick={() => onContentMarkSelect(mark)}
                                                                className={cn(s.item, {
                                                                    [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                                    [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                                    [s.selected]: selectedContentMarks.some((x) => x.id === mark.id),
                                                                })} key={mark.id}>
                                                                <div className={s.itemText}>{mark.name}</div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Row>
                                        </Column>
                                    </Row>
                                ))}
                        </Column>
                    </Row>
                </Column>
            </Row>
            <Row margin={{ top: 13 }}>
                <Column grow>
                    <Tabs activeTab={stickersListMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                    <StickersList
                        sortField={sortField?.toString()}
                        sortDirection={parseInt(sortDirection?.toString() ?? DEFAULT_SORT_DIRECTION.toString())}
                        filters={selectedContentMarks}
                        scrollIndex={scrollIndex ? parseInt(scrollIndex) : undefined}
                        searchQuery={enteredSearchQuery}
                        stickersListMode={stickersListMode}
                        setStickerToEdit={setStickerToEdit}/>
                </Column>
            </Row>
        </Column>
    );
};
