import {
    Button,
    Column,
    EColumnAlignment,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    ETooltipPosition,
    ITextFieldMethods,
    Label,
    Row,
    Switch,
    TextArea,
    TextField,
    Tooltip,
    useModals,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import {
    IconEtmaASignAlt,
    IconEtmaESignAlt,
    IconEtmaMSignAlt,
    IconEtmaTSignAlt,
    IconImageSearch,
    IconMagic,
    IconPoopAltSolid,
    IconSendMessageAltSolid,
    IconUserMdLine,
    IconUserSquareLine,
} from '@assets/images/svg';
import { DEFAULT_SOUND_VOLUME } from '@common';
import { TApp, useAppActions, useAppSelector, useStickerEditor } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import {
    ContentMarkAction,
    EtmaRating,
    IModerationShift,
    ISticker,
    ModerationState,
    SizeCalculationBase,
} from '@typings';
import {
    DogLoader,
    Empty,
    GameSelect,
    GameSelectMethods,
    ModerationStickerOverlay,
    Sticker,
    StickerPlayer,
    StreamerSelect,
    StreamerSelectMethods,
    TagSelect,
    TagSelectMethods,
} from '@views/fragments';
import { RejectStickerPopup } from '@views/modals';

import s from './StickerEditor.scss';

export enum StickerEditorMode {
    ADMIN = 'ADMIN',
    MODERATION = 'MODERATION',
}

interface StickerEditorProps extends TApp {
    stickerEditorMode?: StickerEditorMode;
    stickerToEdit?: ISticker;
    isStickerToEditFetching?: boolean;
    moderationShift?: IModerationShift;
    onAfterSave?: () => Promise<void>;
    onBackButtonClick?: () => Promise<void>;
    onPostponeButtonClick?: () => Promise<void>;
}

export const StickerEditor = () => {
    const { stickerId } = useParams();
    const {
        translation: {
            t,
        },
        menu: {
            leftSideMenu: {
                setHiddenLeftSideBar,
            },
        },
        contentMarks: {
            contentMarks,
            contentMarksByCategory,
        },
        stickersCreation: {
            playingStickersList: {
                list,
                addToList,
                removeFromList,
            },
        },
        navigation: {
            goToViewersSearch,
            goToStreamersSearch,
        },
        stickerToEdit,
        stickerEditorMode = StickerEditorMode.MODERATION,
        isStickerToEditFetching = false,
        moderationShift,
        onBackButtonClick,
        onPostponeButtonClick,
        onAfterSave,
    } = useOutletContext<StickerEditorProps>();

    const {
        currentSticker,
        name,
        description,
        speech2text,
        ocrText,
        music,
        hasCopyright,
        etmaRating,
        defaultStreamerValue,
        defaultGameValue,
        selectedTags,
        isApprovalProhibited,
        isTwitchUnsafe,
        currentContentMarkIds,
        isStickerFetching,
        isStickerUpdating,
        setName,
        setDescription,
        setSpeech2text,
        setOcrText,
        setMusic,
        setHasCopyright,
        setIsTwitchUnsafe,
        setEtmaRating,
        onStreamerSelect,
        onGameSelect,
        setSelectedTags,
        onContentMarkSelect,
        saveState,
    } = useStickerEditor({ stickerId, stickerToEdit, contentMarks });

    const { goBack } = useNavigator();
    const { showModal } = useModals();

    const topRef = useRef<HTMLDivElement>(null);
    const nameRef = useRef<ITextFieldMethods>(null);
    const descriptionRef = useRef<ITextFieldMethods>(null);
    const speech2textRef = useRef<ITextFieldMethods>(null);
    const ocrTextRef = useRef<ITextFieldMethods>(null);
    const musicRef = useRef<ITextFieldMethods>(null);
    const streamerRef = useRef<StreamerSelectMethods>(null);
    const gameRef = useRef<GameSelectMethods>(null);
    const tagsRef = useRef<TagSelectMethods>(null);

    const showLoader = isStickerFetching || isStickerUpdating || isStickerToEditFetching;

    const { switchPlaySoundOnStickerHover } = useAppActions();
    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);

    const onStickerClick = (sticker: ISticker) => {
        addToList(sticker);
    };

    const formatDate = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const kFormatter = (num: number) => {
        return Math.abs(num) > 999
            ? Math.sign(num)*(parseFloat((Math.abs(num)/1000).toFixed(2))) + 'K'
            : Math.sign(num)*Math.abs(num);
    };

    const playingStickers = list.map((sticker) => (
        <StickerPlayer
            key={sticker.uid}
            sticker={sticker.sticker}
            geometry={sticker.geometry}
            onPlayEnd={() => removeFromList(sticker.uid)}
        />
    ));

    const onSaveClick = async (moderationState?: ModerationState, rejectReason?: string) => {
        await saveState(moderationState, rejectReason);

        if (stickerEditorMode === StickerEditorMode.MODERATION) {
            onAfterSave && await onAfterSave();
        }
    };

    const onBackClick = async () => {
        if (stickerEditorMode === StickerEditorMode.MODERATION) {
            onBackButtonClick && await onBackButtonClick();
        } else {
            goBack();
        }
    };

    const onPostponeClick = async () => {
        onPostponeButtonClick && await onPostponeButtonClick();
    };

    const onRejectSticker = () => {
        showModal({
            content: (
                <RejectStickerPopup
                    onRejectClick={onRejectStickerCallback}
                />
            ),
            options: {},
        });
    };

    const onRejectStickerCallback = async (reason: string) => {
        await onSaveClick(ModerationState.DECLINED, reason);
    };

    const isModerationMode = stickerEditorMode === StickerEditorMode.MODERATION;
    const isModerationShiftOpen = moderationShift?.isOpen;

    const insights = (currentSticker: ISticker) => {
        let text = '';
        const stickerInsights = currentSticker.insights;

        if (stickerInsights) {
            if (stickerInsights.insights?.YandexWorker) {
                text += 'Yandex:\n';
                text += `   Adult: ${stickerInsights.insights?.YandexWorker.adult.toFixed(3)}\n`;
                text += `   Gruesome: ${stickerInsights.insights?.YandexWorker.gruesome.toFixed(3)}\n`;
            }

            if (stickerInsights.insights?.OpenaiWorker) {
                text += 'ChatGPT:\n';

                if (stickerInsights.insights?.OpenaiWorker.text) {
                    const textEtma = stickerInsights.insights?.OpenaiWorker.text?.rating ?? 0;

                    text += '   Text:\n';
                    text += `       Rating: ${EtmaRating[textEtma]}\n`;

                    const textProps = stickerInsights.insights?.OpenaiWorker.text as Record<string, string | number>;

                    for (const prop of Object.keys(textProps)) {
                        if (prop !== 'rating' && textProps[prop]) {
                            text += `       ${prop}: ${textProps[prop]}\n`;
                        }
                    }
                }

                if (stickerInsights.insights?.OpenaiWorker.image) {
                    const imageEtma = stickerInsights.insights?.OpenaiWorker.image?.rating ?? 0;

                    text += '   Image:\n';
                    text += `       Rating: ${EtmaRating[imageEtma]}\n`;

                    const imageProps = stickerInsights.insights?.OpenaiWorker.image as Record<string, string | number>;

                    for (const prop of Object.keys(imageProps)) {
                        if (prop !== 'rating' && imageProps[prop]) {
                            text += `       ${prop}: ${imageProps[prop]}\n`;
                        }
                    }
                }
            }
        }

        return text;
    };

    useEffect(() => {
        nameRef.current?.set(name ?? '');
    }, [name]);

    useEffect(() => {
        descriptionRef.current?.set(description ?? '');
    }, [description]);

    useEffect(() => {
        speech2textRef.current?.set(speech2text ?? '');
    }, [speech2text]);

    useEffect(() => {
        ocrTextRef.current?.set(ocrText ?? '');
    }, [ocrText]);

    useEffect(() => {
        musicRef.current?.set(music ?? '');
    }, [music]);

    useEffect(() => {
        streamerRef.current?.set('', defaultStreamerValue);
    }, [defaultStreamerValue]);

    useEffect(() => {
        gameRef.current?.set('', defaultGameValue);
    }, [defaultGameValue]);

    useEffect(() => {
        tagsRef.current?.set('', selectedTags);
    }, [selectedTags]);

    useEffect(() => {
        topRef.current?.scrollIntoView();
    }, [currentSticker]);

    useEffect(() => {
        setHiddenLeftSideBar(true);
    }, []);

    return (
        <div ref={topRef} className={s.scrollFix}>
            <Row grow justification={ERowJustification.CENTER} className={cn(
                s.loader,
                { [s.loaderHidden]: !isStickerUpdating && !isStickerFetching && !showLoader },
            )}>
                <DogLoader size={150}/>
            </Row>
            {currentSticker && !(isModerationMode && !isModerationShiftOpen) ? (
                <>
                    <Column grow rowGap={10} className={s.stickerEditor}>
                        <Row style={{ marginTop: 10 }}>
                            <Button
                                caption={t('moderator-area.sticker-editor.back-button')}
                                onClick={onBackClick}
                                className={cn({
                                    [s.invisible]: (stickerEditorMode === StickerEditorMode.MODERATION && !onBackButtonClick),
                                })}
                            />
                        </Row>
                        <Row grow columnGap={25} alignment={ERowAlignment.TOP}>
                            <Column rowGap={10}>
                                <Row>
                                    <Sticker
                                        className={s.stickerEditorSticker}
                                        sticker={currentSticker}
                                        withSound={playSoundOnStickerHover}
                                        soundOnHover
                                        loop
                                        useFadeInOnLoaded
                                        useSkeletonOnLoading
                                        useDefaultOverlays={false}
                                        overlay={
                                            <ModerationStickerOverlay
                                                sticker={currentSticker}
                                                soundEnabled={playSoundOnStickerHover}
                                                onMuteClick={switchPlaySoundOnStickerHover}
                                            />
                                        }
                                        volume={DEFAULT_SOUND_VOLUME}
                                        sizeCalculationBase={SizeCalculationBase.WIDTH}
                                        onClick={onStickerClick}
                                    />
                                </Row>
                                <Row grow columnGap={10} alignment={ERowAlignment.TOP}>
                                    <Column rowGap={10} className={s.stickerEditorIconsColumn}>
                                        <Row className={cn(s.icon, s.iconGood, {
                                            [s.iconDanger]: !currentSticker.creatorTotalApprovedPercent ||
                                    currentSticker.creatorTotalApprovedPercent < 70,
                                        })}>
                                            <IconUserSquareLine/>
                                        </Row>
                                    </Column>
                                    <Column className={s.stickerEditorTextColumn}>
                                        <Row className={cn(s.title, s.titleGood, s.clickable, {
                                            [s.titleDanger]: !currentSticker.creatorTotalApprovedPercent ||
                                            currentSticker.creatorTotalApprovedPercent < 70,
                                        })
                                        }>
                                            <div onClick={() => goToViewersSearch(currentSticker?.creatorId)}>
                                                {currentSticker.creatorName ?? '-'}
                                            </div>
                                        </Row>
                                        <Row className={cn(s.subtitle, s.subtitleGood, {
                                            [s.subtitleDanger]: !currentSticker.creatorTotalApprovedPercent ||
                                            currentSticker.creatorTotalApprovedPercent < 70,
                                        })
                                        }>
                                            {Math.round(currentSticker.creatorTotalApprovedPercent ?? 0)} %
                                            ({currentSticker.creatorTotalApproved})
                                        </Row>
                                        <Row className={s.subtitle}>
                                            <span className={s.date}>{formatDate(currentSticker.createdAt ?? 0)}</span>
                                        </Row>
                                    </Column>
                                </Row>
                                <Row grow columnGap={10} alignment={ERowAlignment.TOP}>
                                    <Column rowGap={10} className={s.stickerEditorIconsColumn}>
                                        <Row className={s.icon}>
                                            <IconUserMdLine/>
                                        </Row>
                                    </Column>
                                    <Column className={s.stickerEditorTextColumn}>
                                        <Row className={s.title}>
                                            {currentSticker.moderatorName ? currentSticker.moderatorName : '-'}
                                        </Row>
                                        {currentSticker.moderatedAt &&
                                    <Row className={s.subtitle}>
                                        {formatDate(currentSticker.moderatedAt ?? 0)}
                                    </Row>
                                        }
                                    </Column>
                                </Row>
                                <Row grow className={s.stickerEditorLog}>
                                    <Column grow rowGap={10}>
                                        <Row>
                                            <div className={s.title}>{t('moderator-area.common.log')}</div>
                                        </Row>
                                        {currentSticker.moderationLog?.map((item) => (
                                            <Row key={item._id} columnGap={5} className={s.stickerEditorLogItem}>
                                                <Column>
                                                    <Row className={s.text}>
                                                        {formatDate(item.timestamp ?? 0)}
                                                    </Row>
                                                </Column>
                                                <Column>
                                                    <Row
                                                        className={cn(s.text, s.user, {
                                                            [s.clickable]: !!item.userName,
                                                        })}
                                                    >
                                                        <div onClick={item.userName
                                                            ? (item.kind === 'BlacklistCheck'
                                                                ? () => goToStreamersSearch(item.userId)
                                                                : () => goToViewersSearch(item.userId))
                                                            : noop}>
                                                            {item.userName ?? '-'}
                                                        </div>
                                                    </Row>
                                                </Column>
                                                <Column>
                                                    <Row className={s.text}>{item.kind}</Row>
                                                </Column>
                                            </Row>
                                        ))}
                                    </Column>
                                </Row>
                            </Column>
                            <Column rowGap={15} width={410}>
                                <Row alignment={ERowAlignment.CENTER}>
                                    <div className={s.stickerEditorModerationState}>
                                        <div className={cn(s.item, s.itemBold, {
                                            [s.itemWarning]: currentSticker.moderationState === ModerationState.MODERATION ||
                                            currentSticker.moderationState === ModerationState.POST_MODERATION,
                                            [s.itemDanger]: currentSticker.moderationState === ModerationState.DECLINED,
                                            [s.itemSuccess]: currentSticker.moderationState === ModerationState.APPROVED,
                                        })}>
                                            <div className={s.itemText}>
                                                {t(`moderator-area.moderation-state.state-${
                                                    currentSticker.moderationState.toLowerCase()
                                                }`)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.stickerEditorIcon}>
                                        <IconSendMessageAltSolid/>
                                    </div>
                                    <div
                                        className={s.stickerEditorCounter}>{kFormatter(currentSticker.usageCount ?? 0)}</div>
                                    <div className={s.stickerEditorIcon}>
                                        <IconPoopAltSolid/>
                                    </div>
                                    <div
                                        className={s.stickerEditorCounter}>{kFormatter(currentSticker.disableCount ?? 0)}</div>
                                    <div className={s.stickerEditorIcon}>
                                        <Tooltip
                                            position={ETooltipPosition.BOTTOM}
                                            content={
                                                <div style={{ whiteSpace: 'pre', width: 150, padding: 10 }}>{insights(currentSticker)}</div>
                                            }>
                                            <IconMagic/>
                                        </Tooltip>
                                    </div>
                                </Row>
                                <Row grow>
                                    <TextField
                                        methodsRef={nameRef}
                                        caption={t('moderator-area.common.name')}
                                        width={410}
                                        onChanged={setName}
                                    />
                                </Row>
                                <Row>
                                    <Column>
                                        <Label padding={{ top: 0 }}
                                            caption={t('moderator-area.common.description')} size={EComponentSize.MEDIUM}/>
                                        <TextArea
                                            methodsRef={descriptionRef}
                                            maxRows={2}
                                            width={410}
                                            onChange={(value) => setDescription(value?.toString())}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column>
                                        <Label padding={{ top: 0 }}
                                            caption={t('moderator-area.common.speech2text')} size={EComponentSize.MEDIUM}/>
                                        <TextArea
                                            methodsRef={speech2textRef}
                                            maxRows={2}
                                            width={410}
                                            onChange={(value) => setSpeech2text(value?.toString())}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column>
                                        <Label padding={{ top: 0 }} caption={t('moderator-area.common.ocr-text')}
                                            size={EComponentSize.MEDIUM}/>
                                        <TextArea
                                            methodsRef={ocrTextRef}
                                            maxRows={2}
                                            width={410}
                                            onChange={(value) => setOcrText(value?.toString())}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <TextField
                                        caption={t('moderator-area.common.music')}
                                        methodsRef={musicRef}
                                        width={410}
                                        onChanged={setMusic}
                                    />
                                </Row>
                                <Row alignment={ERowAlignment.CENTER} style={{ marginBottom: 10 }}>
                                    <Label padding={0} style={{ marginRight: 15 }}
                                        caption={t('moderator-area.common.has-copyright')} size={EComponentSize.MEDIUM}/>
                                    <Switch
                                        checked={hasCopyright}
                                        color={EComponentColor.WARNING}
                                        onChange={setHasCopyright}
                                    />
                                </Row>
                                <Row>
                                    <Column grow>
                                        <Label padding={{ top: 0 }}
                                            caption={t('moderator-area.common.streamer')} size={EComponentSize.MEDIUM}/>
                                        <StreamerSelect
                                            debounceTime={500}
                                            maxLength={100}
                                            methodsRef={streamerRef}
                                            onSelect={onStreamerSelect}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column grow>
                                        <Label padding={{ top: 0 }}
                                            caption={t('moderator-area.common.game')} size={EComponentSize.MEDIUM}/>
                                        <GameSelect
                                            debounceTime={500}
                                            maxLength={100}
                                            methodsRef={gameRef}
                                            onSelect={onGameSelect}
                                        />
                                    </Column>
                                </Row>
                                <Row alignment={ERowAlignment.TOP}>
                                    <Column grow>
                                        <Label padding={{ top: 0 }} caption={t('moderator-area.common.tags')}
                                            size={EComponentSize.MEDIUM}/>
                                        <TagSelect
                                            fullWidth
                                            placeholder={t('sticker-creation.add-details.add-tag')}
                                            debounceTime={500}
                                            maxLength={20}
                                            methodsRef={tagsRef}
                                            onTagsChanged={setSelectedTags}
                                        />
                                    </Column>
                                </Row>
                            </Column>
                            <Column grow style={{ paddingLeft: 20 }}>
                                <Row>
                                    <Label padding={{ top: 0 }}
                                        caption={t('moderator-area.sticker-editor.content-marks')}
                                        size={EComponentSize.MEDIUM}/>
                                </Row>
                                {contentMarksByCategory && Object.keys(contentMarksByCategory).map((category) => (
                                    <Row key={category}>
                                        <Column grow>
                                            <Row>
                                                <Label padding={{ top: 0 }} caption={category} size={EComponentSize.SMALL}/>
                                            </Row>
                                            <Row grow>
                                                <div className={s.stickerEditorMarks}>
                                                    {contentMarksByCategory[category].map((mark) => (
                                                        <Tooltip key={mark.id} position={ETooltipPosition.TOP} content={mark.comment}>
                                                            <div
                                                                onClick={() => onContentMarkSelect(mark)}
                                                                className={cn(s.item, {
                                                                    [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                                    [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                                    [s.selected]: currentContentMarkIds.includes(mark.id ?? ''),
                                                                })}>
                                                                <div className={s.itemText}>{mark.name}</div>
                                                            </div>
                                                        </Tooltip>
                                                    ))}
                                                </div>
                                            </Row>
                                        </Column>
                                    </Row>
                                ))}
                                <Row className={s.bottomSeparator}></Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Column grow rowGap={15}>
                                        <Row>
                                            <Label padding={{ top: 0 }}
                                                caption={t('moderator-area.sticker-editor.content-rating')}
                                                size={EComponentSize.MEDIUM}/>
                                        </Row>
                                        <Row>
                                            <div className={s.etma}>
                                                <div
                                                    className={cn(s.etmaButton, { [s.selectedE]: etmaRating === EtmaRating.E })}
                                                    onClick={() => setEtmaRating(EtmaRating.E)}>
                                                    <IconEtmaESignAlt/>
                                                </div>
                                                <div
                                                    className={cn(s.etmaButton, { [s.selectedT]: etmaRating === EtmaRating.T })}
                                                    onClick={() => setEtmaRating(EtmaRating.T)}>
                                                    <IconEtmaTSignAlt/>
                                                </div>
                                                <div
                                                    className={cn(s.etmaButton, { [s.selectedM]: etmaRating === EtmaRating.M })}
                                                    onClick={() => setEtmaRating(EtmaRating.M)}>
                                                    <IconEtmaMSignAlt/>
                                                </div>
                                                <div
                                                    className={cn(s.etmaButton, { [s.selectedA]: etmaRating === EtmaRating.A })}
                                                    onClick={() => setEtmaRating(EtmaRating.A)}>
                                                    <IconEtmaASignAlt/>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row
                                            grow
                                            className={s.bottomSeparator}
                                            alignment={ERowAlignment.CENTER}
                                            style={{ marginBottom: 10, paddingBottom: 25 }}>
                                            <Label
                                                padding={0}
                                                style={{ marginRight: 15 }}
                                                caption={t('moderator-area.common.twitch-unsafe')}
                                                size={EComponentSize.MEDIUM}
                                            />
                                            <Switch
                                                checked={isTwitchUnsafe}
                                                color={EComponentColor.TWITCH}
                                                onChange={setIsTwitchUnsafe}
                                            />
                                        </Row>
                                        <Row columnGap={15}>
                                            <Column>
                                                {currentSticker?.moderationState !== ModerationState.DECLINED &&
                                            <Button
                                                caption={t('moderator-area.common.reject')}
                                                color={EComponentColor.DANGER}
                                                onClick={() => onRejectSticker()}
                                            />
                                                }
                                            </Column>
                                            <Column grow alignment={EColumnAlignment.END}>
                                                <Row columnGap={15}>
                                                    {currentSticker?.moderationState !== ModerationState.APPROVED &&
                                                <Button
                                                    caption={t('moderator-area.common.approve')}
                                                    disabled={isApprovalProhibited}
                                                    color={EComponentColor.SUCCESS}
                                                    onClick={() => onSaveClick(ModerationState.APPROVED)}
                                                />
                                                    }
                                                    {stickerEditorMode === StickerEditorMode.ADMIN &&
                                                <Button
                                                    caption={t('moderator-area.common.save')}
                                                    color={EComponentColor.WARNING}
                                                    width={160}
                                                    onClick={() => onSaveClick()}
                                                />
                                                    }
                                                </Row>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column grow alignment={EColumnAlignment.END}>
                                                {stickerEditorMode === StickerEditorMode.MODERATION && onPostponeButtonClick &&
                                                    <Button
                                                        caption={t('moderator-area.common.postpone')}
                                                        width={160}
                                                        onClick={onPostponeClick}
                                                    />
                                                }
                                            </Column>
                                        </Row>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </Column>
                    <div className={s.stickersShadow}>
                        {playingStickers}
                    </div>
                </>)
                : (isModerationMode && !isModerationShiftOpen ? (
                    <Empty
                        padding={{ top: 70 }}
                        icon={<IconUserMdLine/>}
                        iconSize={50}
                        text={t('moderator-area.sticker-editor.shift-closed')}
                    />)
                    : isModerationMode && (
                        <Empty
                            padding={{ top: 70 }}
                            icon={<IconImageSearch/>}
                            iconSize={50}
                            text={t('moderator-area.sticker-editor.no-more-stickers')}
                        />
                    )
                )}
        </div>
    );
};
