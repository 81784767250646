import { useEffect, useState } from 'react';

import { useApi } from '@hooks';

export interface IBadWordsParams {
    dictionaryName: string;
    testQuery: string;
}

export const useBadWords = (params: IBadWordsParams) => {
    const { dictionaryName, testQuery } = params;
    const {
        moderation: {
            useGetBadWordsQuery,
            useSaveBadWordsMutation,
            useLazyCheckBadWordsQuery,
        },
    } = useApi();

    const [joinedBadWords, setJoinedBadWords] = useState<string>();
    const [checkResult, setCheckResult] = useState<string>('');
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    const {
        data: loadedBadWords,
        isLoading: isBadWordsLoading,
        isFetching: isBadWordsFetching,
    } = useGetBadWordsQuery(dictionaryName, { skip: !dictionaryName });

    const [
        check,
        {
            isFetching: isCheckFetching,
        },
    ] = useLazyCheckBadWordsQuery();

    const [save] = useSaveBadWordsMutation();

    const processBadWords = async () => {
        if (loadedBadWords && loadedBadWords.badWords.length > 0) {
            const joinedWords = loadedBadWords.badWords.substring(1, loadedBadWords.badWords.length - 1).split('|').join('\n');
            setJoinedBadWords(joinedWords);
        } else {
            setJoinedBadWords(undefined);
        }

        await checkQuery(testQuery);
    };

    const saveBadWords = async () => {
        if (joinedBadWords) {
            let prepared;

            if (dictionaryName === 'extremism') {
                prepared = joinedBadWords.replace(/\n\n/g, '\n').split('\n').join('|').toLowerCase();
            } else {
                prepared = joinedBadWords.replace(/ /g, '').replace(/\n\n/g, '\n').split('\n').join('|').toLowerCase();
            }

            if (prepared.lastIndexOf('|') == prepared.length - 1) {
                prepared = prepared.substring(0, prepared.length - 1);
            }

            const badWords = `(${prepared})`;
            const saveResult = await save({ badWords, dictionaryName }).unwrap();

            if (!saveResult) {
                setHasErrors(true);
            }
        }
    };

    const checkQuery = async (query?: string) => {
        if (query) {
            const response = await check({ query, dictionaryName }).unwrap();

            if (response && response.result) {
                return setCheckResult(response.result);
            }
        }

        setCheckResult('');
    };

    const onJoinedBadWordsChange = async (value?: string) => {
        setJoinedBadWords(value);
    };

    useEffect(() => {
        setHasErrors(false);
    }, [joinedBadWords]);

    useEffect(() => {
        void processBadWords();
    }, [loadedBadWords]);

    useEffect(() => {
        void checkQuery(testQuery);
    }, [testQuery]);

    return {
        hasErrors,
        joinedBadWords,
        checkResult,
        isBadWordsLoading,
        isBadWordsFetching,
        isCheckFetching,
        onJoinedBadWordsChange,
        checkQuery,
        saveBadWords,
    };
};
