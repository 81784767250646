import { Button, Column, EComponentColor, EComponentSize, Label, Loader, Row, Switch, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import { t } from 'i18next';
import React, { createRef, useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import {
    ContentMarkAction,
    IContentMark,
    IContentMarksByCategory,
    IGame,
    IMassStickersUpdate,
    ITag,
    IUser,
} from '@typings';
import { GameSelect, StreamerSelect, TagSelect } from '@views/fragments';

import s from './MassTagPopup.scss';

interface MassTagPopupProps {
    filter?: string;
    filterContentMarks?: IContentMark[];
    stickersCount?: number;
    contentMarksByCategory?: IContentMarksByCategory;
    massUpdateStickers?: (data: IMassStickersUpdate) => Promise<number>;
}

export const MassTagPopup = (props: MassTagPopupProps) => {
    const { closeModal } = useModals();
    const {
        filter,
        filterContentMarks,
        stickersCount,
        contentMarksByCategory,
        massUpdateStickers = noop,
    } = props;

    const approveLayerRef = createRef<HTMLDivElement>();

    const [streamer, setStreamer] = useState<IUser>();
    const [game, setGame] = useState<IGame>();
    const [addedTags, setAddedTags] = useState<ITag[]>();
    const [removedTags, setRemovedTags] = useState<ITag[]>();
    const [isNotTwitchSafe, setIsNotTwitchSafe] = useState<boolean>(false);

    const [removeMarks, setRemoveMarks] = useState<boolean>(false);
    const [selectedContentMarks, setSelectedContentMarks] = useState<IContentMark[]>([]);

    const [isVisibleApproveLayer, setIsVisibleApproveLayer] = useState(false);
    const [isEditInProcess, setIsEditInProcess] = useState(false);

    const isValidValues =
        (((filter && filter.length > 0) || (filterContentMarks && filterContentMarks.length > 0)) &&
            (game ||
                streamer ||
                (addedTags && addedTags.length > 0) ||
                (removedTags && removedTags.length > 0) ||
                selectedContentMarks && selectedContentMarks.length > 0 ||
                isNotTwitchSafe));

    const onContentMarkSelect = (contentMark: IContentMark) => {
        if (selectedContentMarks.some((m) => m._id === contentMark._id)) {
            setSelectedContentMarks(selectedContentMarks.filter((m) => m._id !== contentMark.id));
        } else {
            setSelectedContentMarks([...selectedContentMarks, contentMark]);
        }
    };

    const onApproveShadowLayerClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target !== approveLayerRef.current) return;
        event.stopPropagation();

        setIsVisibleApproveLayer(false);
    };

    const onApproveCancelClick = async () => {
        setIsVisibleApproveLayer(false);
    };

    const onApproveClick = async () => {
        if (isValidValues) {
            try {
                setIsEditInProcess(true);
                await massUpdateStickers({
                    query: filter,
                    streamerId: streamer?._id,
                    gameId: game?._id,
                    filterContentMarks: filterContentMarks && filterContentMarks.length > 0
                        ? filterContentMarks.map((x) => x.id) : undefined,
                    selectedContentMarks: selectedContentMarks && selectedContentMarks.length > 0
                        ? selectedContentMarks.map((x) => x.id) : undefined,
                    addedTags,
                    removedTags,
                    isNotTwitchSafe,
                    removeMarks,
                });
                closeModal();
            } catch {
                setIsEditInProcess(false);
            }
        }
    };

    return (
        <div className={s.massTagPopup}>
            { isVisibleApproveLayer &&
                <div className={s.approveLayer} ref={approveLayerRef} onClick={onApproveShadowLayerClick}>
                    <div className={s.content}>
                        <div className={s.question}>{t('mass-tag-popup.apply-title')}</div>
                        <div className={s.textHint}>
                            {t('mass-tag-popup.apply-changes', { stickersCount: stickersCount ?? '' })}
                        </div>
                        {isEditInProcess
                            ? <div className={s.loader}>
                                <Loader className={s.spinner}/>
                            </div>
                            : <div className={s.buttons}>
                                <Button
                                    caption={t('common.yes')}
                                    fullWidth
                                    onClick={onApproveClick}
                                    color={EComponentColor.DANGER}
                                />
                                <Button
                                    caption={t('common.no')}
                                    fullWidth
                                    onClick={onApproveCancelClick}
                                    color={EComponentColor.ACCENT}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            <Column grow rowGap={10} className={s.massTagPopupContent}>
                <Label caption={t('mass-tag-popup.mass-edit')} className={s.massTagPopupTopicLabel} />
                <Row>
                    <StreamerSelect
                        debounceTime={500}
                        fullWidth
                        placeholder={t('mass-tag-popup.streamer')}
                        onSelect={(streamer) => setStreamer(streamer)}
                    />
                </Row>
                <Row>
                    <GameSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.game')}
                        debounceTime={500}
                        maxLength={100}
                        onSelect={(game) => setGame(game)}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.add-tag')}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setAddedTags}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.delete-tag')}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setRemovedTags}
                    />
                </Row>
                <Row>
                    <Switch
                        style={{ marginTop: 10, marginBottom: 10 }}
                        color={EComponentColor.TWITCH}
                        caption={t('moderator-area.common.twitch-unsafe')}
                        onChange={(value) => setIsNotTwitchSafe(value)}
                    />
                </Row>
                <Row columnGap={10} margin={{ bottom: 10 }}>
                    <Switch
                        caption={t('mass-tag-popup.remove-marks-caption')}
                        color={EComponentColor.DANGER}
                        checked={removeMarks}
                        onChange={setRemoveMarks}
                    />
                </Row>
                <Row>
                    <Label
                        caption={
                            (removeMarks ? t('mass-tag-popup.deleted-label') : t('mass-tag-popup.added-label')) + ':'
                        }
                        padding={{ top: 0 }}
                        size={EComponentSize.MEDIUM}
                    />
                </Row>
                {contentMarksByCategory && Object.keys(contentMarksByCategory)
                    .map((category) => (
                        <Row key={category}>
                            <Column grow>
                                <Row>
                                    <Label
                                        padding={{ top: 0 }}
                                        caption={category}
                                        size={EComponentSize.SMALL}
                                    />
                                </Row>
                                <Row grow>
                                    <div className={s.contentMarks}>
                                        {contentMarksByCategory[category]
                                            .map((mark) => (
                                                <div
                                                    onClick={() => onContentMarkSelect(mark)}
                                                    className={cn(s.item, {
                                                        [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                        [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                        [s.selected]: selectedContentMarks.some((x) => x.id === mark.id),
                                                    })} key={mark.id}>
                                                    <div className={s.itemText}>{mark.name}</div>
                                                </div>
                                            ))}
                                    </div>
                                </Row>
                            </Column>
                        </Row>
                    ))}
                <Row className={s.footerRow}>
                    <Button
                        color={EComponentColor.WARNING}
                        caption={t('common.save')}
                        onClick={() => isValidValues && setIsVisibleApproveLayer(true)}
                        disabled={!isValidValues}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </div>
    );
};
