import { Loader, EComponentColor, EComponentSize } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useRef } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { IGame } from '@typings';

import s from './GameSelect.scss';
import { useGameSelect } from './hooks/GameSelect';

export interface GameSelectMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string, game?: IGame) => void;
}

export interface GameSelectDefaultValue {
    id?: string;
    coverThumbId?: string;
    name?: string;
}

interface GameSelectProps {
    className?: string;
    color?: EComponentColor;
    debounceTime?: number;
    defaultValue?: GameSelectDefaultValue;
    methodsRef?: React.MutableRefObject<GameSelectMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    fullWidth?: boolean;
    placeholder?: string;
    readonly?: boolean;
    onSelect?: (game?: IGame) => void;
}

export const GameSelect = (props: GameSelectProps) => {
    const {
        className,
        color = EComponentColor.ACCENT,
        methodsRef,
        maxWidth,
        minWidth,
        fullWidth,
        placeholder,
        onSelect = noop,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
        isLoadInProgress,
        searchResults,
        selectedGame,
        value,
        onChange,
        setSelectedGame,
        set,
        reset,
    } = useGameSelect({ ...props });

    const styles = {
        minWidth,
        maxWidth,
    };

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const onGameClick = (game: IGame) => {
        setTimeout(() => {
            setSelectedGame(game);
            onSelect(game);
        }, 0);
    };

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color' + color],
        {
            [s.textFieldFullWidth]: fullWidth,
        },
    );

    if (methodsRef) {
        methodsRef.current = {
            reset,
            focus,
            set,
        };
    }

    const getGameItem = (game: IGame, onSelect?: (game: IGame) => void) => {
        return (
            <div
                key={game.id}
                onMouseDown={() => onSelect ? onSelect(game) : noop}
                className={cn(s.gameItem, { [s.gameItemSelectable]: !!onSelect })}>
                <div className={s.gameItemIcon}>
                    <Avatar
                        className={s.gameItemIconAvatar}
                        source={`https://images.igdb.com/igdb/image/upload/t_thumb/${game.coverThumbId}.jpg`}
                        size={EComponentSize.EXTRA_SMALL}
                        rounded
                    />
                </div>
                <div className={s.gameItemText}>{game.name}</div>
            </div>
        );
    };

    return (
        <div className={textFieldClassNames} style={styles} onBlur={() => reset()}>
            <div className={s.textFieldInputContainer}>
                <input
                    className={cn(s.textFieldInput, {
                        [s.textFieldInputInvisible]: !!selectedGame,
                    })}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                />
                { selectedGame &&
                    <div className={s.textFieldSelectedGame}>
                        { getGameItem(selectedGame) }
                    </div>
                }
            </div>

            <div className={s.textFieldActionsContainer}>
                {!selectedGame
                    ? <div className={s.textFieldAction}>
                        <Loader
                            containerClassName={s.loader}
                            className={cn({ [s.loaderHidden]: !isLoadInProgress })}
                            size={20}
                        />
                    </div>

                    : <div
                        className={cn(
                            s.textFieldAction,
                            {
                                [s.textFieldActionHidden]: !selectedGame,
                            },
                        )}
                        onClick={reset}>
                        <IconCloseLine/>
                    </div>
                }
            </div>

            {(searchResults && searchResults?.length > 0) &&
                <div className={s.textFieldDropdown}>
                    { searchResults?.map((res) => getGameItem(res, onGameClick)) }
                </div>
            }
        </div>
    );
};
