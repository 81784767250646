import { StickersListMode } from '@hooks';
import { EtmaRating, ISticker } from '@typings/api/Stickers';
import { IUser } from '@typings/api/Users';

export interface IModeratorAreaPageLoadParams {
    searchQuery: string;
    skip?: number;
    limit?: number;
    filters?: string[];
    sort?: {
        field: string;
        direction: number;
    };
    stickersListMode: StickersListMode;
}

export enum ContentMarkAction {
    ETMA = 'ETMA',
    TWITCH_UNSAFE = 'TWITCH_UNSAFE',
    REJECT = 'REJECT',
}

export interface IContentMark {
    _id: string;
    id: string;
    name: string;
    comment?: string;
    etmaRating?: EtmaRating;
    category: string;
    isTextCheck: boolean;
    isPicCheck: boolean;
    isFaceCheck: boolean;
    isAgeCheck: boolean;
    isSndCheck: boolean;
    actions?: ContentMarkAction[],
    createdAt: number;
    createdBy: boolean;
    order: number;
}

export interface IContentMarksByCategory {
    [key: string]: IContentMark[];
}

export interface IModerationShift {
    id: string;
    userId: string;
    openAt: number;
    closeAt: number;
    isOpen: boolean;
}

export interface IViewerInfoLoadParams {
    skip: number;
    limit: number;
    userId: string;
}

export interface IViewerInfo {
    viewer: IUser,
    wallets: IViewerWalletItem[];
    events: IViewerInfoEventItem[];
}

export interface IViewerWalletItem {
    userId: string;
    balance: number;
    streamerId: string;
    streamerName: string;
}

export interface IViewerInfoEventItem {
    _id: string;
    kind: string;
    fullscreen?: boolean;
    timestamp: number;
    streamerName: string;
    streamerLink: string;
    type: string;
    event: string;
    stickerId?: string;
    stickerName?: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    money: number;
    balance: number;
}

export interface IStreamerInfoMoneyLoadParams {
    page: number;
    streamerId: string;
}

export interface IStreamerInfoMoneyItem {
    year: number;
    month: number;
    day: number;
    events: IStreamerInfoMoneyEventItem[];
    timestamp: number;
    money: number;
}

export interface IStreamerInfoMoneyEventItem {
    _id: string;
    kind: string;
    fullscreen: boolean;
    timestamp: number;
    streamerName: string;
    userName: string;
    userAlias: string;
    userId: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    money: number;
}

export interface IStreamerInfoStickersLoadParams {
    page: number;
    streamerId: string;
}

export interface IStreamerInfoStickersItem {
    _id: string;
    kind: string;
    fullscreen?: boolean;
    timestamp: number;
    streamerName: string;
    type: string;
    event: string;
    userName: string;
    userAlias: string;
    userId: string;
    stickerId?: string;
    stickerName?: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    count: number;
    balance: number;
}

export interface IMemesIndexSearchParams {
    stickerId: string;
}

export interface IMemesIndexSearchResult {
    total: [[ISticker, number]],
    v: [[ISticker, number]],
    a: [[ISticker, number]],
}
